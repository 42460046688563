import React from "react"
import { Link } from "gatsby"
import Image from "../../components/image"
import Layout from "../../components/layout"
// import SEO from "../components/seo"
import "../../assets/sass/landscaping.sass"

const Landscaping = () => (
<div className="info-page landscaping">

    <div className="info-page-intro">
        <h1>landscaping</h1>
        <p>Helping you keep your property healthy, clean, and safe.</p>
    </div>
    
    <Image filename="backyard-landscape.jpg" />

    <div className="discription">

        <p className="landscape-work">Lawn care</p>
        <p className="landscape-work">Lawn Mowing</p>
        <p className="landscape-work">Mulch</p>
        <p className="landscape-work">Shrub pruning</p>
        <p className="landscape-work">Planting</p>
        <p className="landscape-work">De-weeding</p>
        <p className="landscape-work">Landscape design</p>
        <p className="landscape-work">Plastic Edging</p>
        <p className="landscape-work">Garden accessories</p>
        <p className="landscape-motto">What ever your lawn may need, we got you covered.</p>

        <p className="landscape-statement">
            Can your property benefit from a good cleaning? Give us a call to get started on a fall clean-up! 
            Our landscaping and lawn care services are designed to provide the care and attention your property needs.
            We’re excited to get started. 
        </p>
        <p className="landscape-statement">
            At Kevins Fencing and landscaping, we’ll do whatever is needed to get your property ready for the next season.
            We come with years of experience and a reputation of doing exceptional jobs. 
            Getting your landscape back into shape after the end of the season can be a big job, and we have the team to tackle just that.
        </p>

    </div>

</div>

)

export default Landscaping